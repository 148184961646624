import React from 'react';
import Layout from '../components/layout';

const AboutPage = () => {
  return (
    <Layout pageTitle="About">
      <p>The Gatsby tutorial can be found <a href="https://www.gatsbyjs.com/docs/tutorial/">here</a>.</p>
    </Layout>
  );
}

export default AboutPage;
